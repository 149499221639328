import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

//import { ApiContext } from '../contexts/apicontext';
import { useFieldsteadApi } from '../hooks/useFieldsteadApi';
import { AuthenticationInfo } from '../contexts/authcontext';
//import { RestApiRepository } from '../application/restapirepository';
import { Grid, Typography, IconButton, Badge, Button, ButtonGroup, Checkbox, FormControlLabel, makeStyles, createStyles, Theme, List, ListItem, Paper, CircularProgress, Box } from '@material-ui/core';
import { IContactSummary } from '../application/models';
import TextField from '@material-ui/core/TextField';

import { ContactDetails } from './contactdetail';
import { ContactEdit } from './edit/contactbase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPrint, faSearch, faBan, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ExtractLines } from '../application/helperfuncs';
import { Check } from '@material-ui/icons';
import { useAuthenticator } from '@aws-amplify/ui-react';


export interface IDirectoryProps {
    anchor: string;
}

export interface IDirectorySearchState {
    text: string;
    selectedLetter: string;
    selectedFlag: string;
    isLoading: boolean;
    directory: Array<IContactSummary>;
    selectedContact: string | null;
    mode: "search" | "detail" | "edit" | "copy";
    activated: boolean;
    searchtype: number;
}

export interface IDirectoryState {
}

const getDefaultSearchState : () => IDirectorySearchState = () => {
    return {
        text: "",
        selectedLetter: "",
        selectedFlag: "",
        isLoading: false,
        directory: [],
        selectedContact: null,
        mode: "search",
        activated: false,
        searchtype: 0
    };
}

interface IAdvancedSearchList {
    fullname: boolean;
    children: boolean;
    company: boolean;
    relationships: boolean;
    email: boolean;
    location: boolean;
    notes: boolean;
    displaymenu: boolean;
}

export const Directory: React.FC<IDirectoryProps> = (props, context) => {
    const classes = useStyles();
    const history = useNavigate();
    const [searchState, setSearchState] = useState<IDirectorySearchState>(getDefaultSearchState());
    const { GetUserInfo, DeleteContact, FetchDirectoryEntries } = useFieldsteadApi();
    //const [directoryState, setDirectoryState] = useState<IDirectoryState>({});
    //const api: RestApiRepository = useContext(ApiContext);
    const { user } = useAuthenticator((context) => [context.user]);
    const [auth, setAuth] = useState<AuthenticationInfo | null>(null);
    useEffect(() => {
        GetUserInfo().then((auth) => {
            if (auth != null) {
                setAuth(auth);
            }
            else {
                setAuth(null);
            }
        })
    }, [user]);

    const [updateState, setUpdateState] = useState<boolean>(false);

    const defaultSearchList: () => IAdvancedSearchList = () => {
        return {
            fullname: false,
            children: false,
            company: false,
            relationships: false,
            email: false,
            location: false,
            notes: false,
            displaymenu: false
        };
    }

    const [advancedui, setAdvancedUI] = useState<IAdvancedSearchList>(defaultSearchList());

    //Initial population of list
    //useEffect(() => {
    //    api.FetchDirectoryEntries((data) => {
    //        setDirectoryState({ ...directoryState, directory: data, isLoading: false });
    //    });
    //}, [])

    const searchChange: (e: React.ChangeEvent<HTMLInputElement>) => void = (e) => {
        setSearchState({ ...searchState, text: e.target.value, selectedFlag: "", selectedLetter: "" });
    }

    const isFilterEmpty: (filter: IDirectorySearchState) => boolean = (f) => {
        return (f.selectedFlag == null || f.selectedFlag == "") && (f.selectedLetter == null || f.selectedLetter == "") && (f.text == null || f.text == "");
    }

    const getSearchName: (item: IContactSummary) => string = (item) => {
        return item.entityname.toUpperCase().startsWith("THE ") ? item.entityname.substring(4).toUpperCase() : item.entityname.toUpperCase();
    }

    const sortContacts: (a: IContactSummary, b: IContactSummary) => number = (a, b) => {
        let nameA: string = getSearchName(a);
        let nameB: string = getSearchName(b);
        if (nameA > nameB) return 1;
        if (nameB > nameA) return -1;
        return 0;
    }

    const navList: () => void = () => {
        history('/lists');
    }

    const navTags: () => void = () => {
        history('/tags');
    }

    const checkFilter: (item: IContactSummary, ix: number) => boolean = (item, ix) => {
        if (isFilterEmpty(searchState)) return false;
        if (searchState.selectedFlag != null && searchState.selectedFlag != "") {
            if (item.flags == null) {
                return false;
            }
            else if (searchState.selectedFlag.endsWith("*")) {
                let searchFlag: string = searchState.selectedFlag.replace("*", "").toUpperCase();
                if (item.flags.some((i) => { return i.toUpperCase().startsWith(searchFlag); }) == false) {
                    return false;
                }
            }
            else {
                if (item.flags.some((i) => { return i.toUpperCase() == searchState.selectedFlag.toUpperCase();}) == false) {
                    return false;
                }
            }
        }
        let searchName: string = getSearchName(item);
        if (searchState.selectedLetter != null && searchState.selectedLetter != "" && (searchName.startsWith(searchState.selectedLetter) == false)) {
            return false;
        }
        // Search by City, Flags, Phone numbers, email
        if (searchState.text != null && searchState.text != "") {
            return (item.companyName != null && item.companyName.toUpperCase().indexOf(searchState.text.toUpperCase()) >= 0)
                || (item.contactName != null && item.contactName.toUpperCase().indexOf(searchState.text.toUpperCase()) >= 0)
                || (item.entityname != null && item.entityname.toUpperCase().indexOf(searchState.text.toUpperCase()) >= 0)
                || item.associateNames.some((a) => { return a.toUpperCase().indexOf(searchState.text.toUpperCase()) >= 0; });
        }
        return true;
    }
    
    const letterChange: (l: string) => void = (l) => {
        if (l == searchState.selectedLetter && searchState.mode == "search") {
            setSearchState({ ...searchState, selectedLetter: "", isLoading: true, mode: "search", selectedContact: null });
        }
        else {
            setSearchState({ ...searchState, text: "", selectedFlag: "", selectedLetter: l, isLoading: true, mode: "search", selectedContact: null });
        }
    }
    
    const selectItem: (category: string) => void = (c) => {
        if (c == searchState.selectedFlag && searchState.mode == "search") {
            setSearchState({ ...searchState, selectedFlag: "", isLoading: true, mode: "search", selectedContact: null });
        }
        else {
            setSearchState({ ...searchState, text: "", selectedLetter: "", selectedFlag: c, isLoading: true, mode: "search", selectedContact: null });
        }
    }

    const executeSearch: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void = (e) => {
        runSearch();
    }

    const runSearch: () => void = () => {
        setSearchState({ ...searchState, activated: true, isLoading: true, mode: "search", selectedContact: null });
    }

    const clearFilter: () => void = () => {
        let newfilter = defaultSearchList();
        newfilter.displaymenu = true;
        setAdvancedUI(newfilter);
    }

    const toggleAdvancedSearch: () => void = () => {
        //setSearchState({ ...searchState, searchtype: 0 });
        setAdvancedUI({ ...advancedui, displaymenu: !(advancedui.displaymenu)});
    }

    const alphabet: Array<string> = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split("");

    const displayDetails: (id: string) => void = (id) => {
        setSearchState({ ...searchState, selectedContact: id, mode: "detail" });
    }

    const completeEdit: (id: string | null | undefined, success: boolean) => void = (id, success) => {
        if (id != null) {
            displayDetails(id);
            setUpdateState(success);
        }
        else {
            closeDetails();
        }
    }

    const closeDetails: () => void = () => {
        setSearchState({ ...searchState, selectedContact: null, mode: "search" });
        setUpdateState(false);
    }

    const editContact: (id: string | null) => void = (id) => {
        setSearchState({ ...searchState, selectedContact: id, mode: "edit" });
        setUpdateState(false);
    }

    const copyContact: (id: string) => void = (id) => {
        setSearchState({ ...searchState, selectedContact: id, mode: "copy" });
        setUpdateState(false);
    }

    const deactivateContact: (id: string | null) => void = (id) => {
        if (id != null && window.confirm('Are you sure you want to delete this contact?')) {
            DeleteContact(id).then((response) => {
                setSearchState({ ...searchState, selectedContact: null, mode: "search", isLoading: true });
                setUpdateState(false);
            });
        }
    };

    useEffect(() => {
        if (searchState.isLoading) {
            if (isFilterEmpty(searchState)) {
                setSearchState({ ...searchState, directory: [], isLoading: false });
            }
            else {
                FetchDirectoryEntries(searchState.selectedLetter, searchState.selectedFlag, searchState.text, advancedui).then((data) => {
                    setSearchState({ ...searchState, directory: data, isLoading: false, text: "" });
                });
            }
        }
    }, [searchState.isLoading]);

    useEffect(() => {
        setSearchState(getDefaultSearchState());
    }, [props.anchor]);
    
    return (
        <Grid container alignItems="flex-start" justifyContent='space-around'>
            <Grid item container xs={9} spacing={2}>
                <Grid item xs={12} container spacing={2}>
                        <Grid item xs={12} className={classes.formFields}><Typography variant="h3">Search Directory</Typography></Grid>
                        <Grid item xs={8} className={classes.formFields}>
                            <TextField value={searchState.text} fullWidth variant='outlined' onChange={searchChange} size='small' disabled={searchState.isLoading} placeholder="Search for..." autoFocus onKeyDown={(e) => { if (e.keyCode == 13) { runSearch(); } }} />
                        </Grid>
                        <Grid item xs={2} alignItems='stretch' className={classes.formFields} container alignContent='center'>
                            <Button variant='contained' color='primary' onClick={executeSearch} disabled={searchState.isLoading}>Search</Button>
                    </Grid>
                    <Grid item xs={1} className={classes.popuphost}>
                        <Button onClick={() => { toggleAdvancedSearch(); }} color="secondary" size="small" variant="contained" startIcon={<Badge variant='dot' color="primary" invisible={!advancedui.fullname && !advancedui.children && !advancedui.company && !advancedui.relationships && !advancedui.email && !advancedui.location && !advancedui.notes}><FontAwesomeIcon size='xs' icon={faSearch} /></Badge> }><Box fontSize='.7em' component='span'>Advanced Search</Box></Button>
                        <Box hidden={!(advancedui.displaymenu)} className={classes.popupbox}>
                            <ButtonGroup size='small' className={ classes.popupButtons}>
                                <Button className={ classes.popupButton } onClick={clearFilter}><FontAwesomeIcon icon={faBan} /></Button>
                                <Button className={classes.popupButton} onClick={toggleAdvancedSearch}><FontAwesomeIcon icon={faTimes} /></Button>
                            </ButtonGroup>
                            <FormControlLabel className={classes.advsearchblock} label={<Typography className={classes.advsearchlabel}>Name</Typography>} control={<Checkbox className={classes.popupcomponent} checked={advancedui.fullname} onChange={() => { setAdvancedUI({ ...advancedui, fullname: !(advancedui.fullname) }); }} />} />
                            <FormControlLabel className={classes.advsearchblock} label={<Typography className={classes.advsearchlabel}>Children</Typography>} control={<Checkbox className={classes.popupcomponent} checked={advancedui.children} onChange={() => { setAdvancedUI({ ...advancedui, children: !(advancedui.children) }); }} />} />
                            <FormControlLabel className={classes.advsearchblock} label={<Typography className={classes.advsearchlabel}>Company</Typography>} control={<Checkbox className={classes.popupcomponent} checked={advancedui.company} onChange={() => { setAdvancedUI({ ...advancedui, company: !(advancedui.company) }); }} />} />
                            <FormControlLabel className={classes.advsearchblock} label={<Typography className={classes.advsearchlabel}>Relationships</Typography>} control={<Checkbox className={classes.popupcomponent} checked={advancedui.relationships} onChange={() => { setAdvancedUI({ ...advancedui, relationships: !(advancedui.relationships) }); }} />} />
                            <FormControlLabel className={classes.advsearchblock} label={<Typography className={classes.advsearchlabel}>Email</Typography>} control={<Checkbox className={classes.popupcomponent} checked={advancedui.email} onChange={() => { setAdvancedUI({ ...advancedui, email: !(advancedui.email) }); }} />} />
                            <FormControlLabel className={classes.advsearchblock} label={<Typography className={classes.advsearchlabel}>Location</Typography>} control={<Checkbox className={classes.popupcomponent} checked={advancedui.location} onChange={() => { setAdvancedUI({ ...advancedui, location: !(advancedui.location) }); }} />} />
                            <FormControlLabel className={classes.advsearchblock} label={<Typography className={classes.advsearchlabel}>Notes</Typography>} control={<Checkbox className={classes.popupcomponent} checked={advancedui.notes} onChange={() => { setAdvancedUI({ ...advancedui, notes: !(advancedui.notes) }); }} />} />
                        </Box>
                    </Grid>
                        <Grid item xs={12}>
                            <ButtonGroup fullWidth variant='outlined' disabled={searchState.isLoading}>
                            <Button onClick={() => { selectItem("DV*"); }} variant={searchState.selectedFlag == "DV*" ? 'contained' : 'outlined'} color={searchState.selectedFlag == "DV*" ? 'primary' : 'default'}><Box fontSize='.8em'>Chauffeurs</Box></Button>
                            <Button onClick={() => { selectItem("DOCS"); }} variant={searchState.selectedFlag == "DOCS" ? 'contained' : 'outlined'} color={searchState.selectedFlag == "DOCS" ? 'primary' : 'default'}><Box fontSize='.8em'>Doctors</Box></Button>
                            <Button onClick={() => { selectItem("GDS*"); }} variant={searchState.selectedFlag == "GDS*" ? 'contained' : 'outlined'} color={searchState.selectedFlag == "GDS*" ? 'primary' : 'default'}><Box fontSize='.8em'>Guides</Box></Button>
                            <Button onClick={() => { selectItem("NEWURB"); }} variant={searchState.selectedFlag == "NEWURB" ? 'contained' : 'outlined'} color={searchState.selectedFlag == "NEWURB" ? 'primary' : 'default'}><Box fontSize='.8em'>New Urbanism</Box></Button>
                            <Button onClick={() => { selectItem("FCOEMP"); }} variant={searchState.selectedFlag == "FCOEMP" ? 'contained' : 'outlined'} color={searchState.selectedFlag == "FCOEMP" ? 'primary' : 'default'}><Box fontSize='.8em'>FCO Employees</Box></Button>
                            <Button onClick={() => { selectItem("RESTAS"); }} variant={searchState.selectedFlag == "RESTAS" ? 'contained' : 'outlined'} color={searchState.selectedFlag == "RESTAS" ? 'primary' : 'default'}><Box fontSize='.8em'>Restaurants</Box></Button>
                            </ButtonGroup>
                        </Grid>
                        <Grid item xs={12}>
                            <ButtonGroup fullWidth size='small' disabled={searchState.isLoading} variant='text'>
                                {alphabet.map((v, i) => {
                                    return (
                                        searchState.selectedLetter == v ?
                                            <Button onClick={() => { letterChange(v); }} color='primary' variant='contained' key={"letter" + i.toString()} className={classes.selectedletterbutton}><Box fontSize='.85em'>{v}</Box></Button>
                                            : <Button onClick={() => { letterChange(v); }} key={"letter" + i.toString()} className={classes.letterbutton}><Box fontSize='.8em'>{v}</Box></Button>
                                    );
                                })}
                            </ButtonGroup>
                    </Grid>
                    <Grid item container xs={12} alignItems="center">
                        {searchState.mode == "detail" ? <Grid item xs={12}><ContactDetails contactid={searchState.selectedContact} editMode={() => { editContact(searchState.selectedContact) }} copyMode={() => { if (searchState.selectedContact != null) { copyContact(searchState.selectedContact); } }} closeContact={closeDetails} isupdated={updateState} requestDelete={ deactivateContact } /></Grid> :
                            (searchState.mode == "edit" || searchState.mode == "copy") ? <Grid item xs={12}><ContactEdit contactid={searchState.selectedContact} completeEdit={completeEdit} isCopy={searchState.mode == "copy"} /></Grid> :
                            <Grid item xs={12}>
                                {searchState.isLoading ? <CircularProgress />
                                        : <React.Fragment>
                                            {(searchState.directory != null && searchState.activated == true && searchState.isLoading == false) ? searchState.directory.length > 0 ? <Typography variant='h4'>{searchState.directory.length.toString()} results returned</Typography> : <Typography variant='h4'>Sorry, no results were returned.</Typography> : null}
                                            <List>
                                            {searchState.directory.sort(sortContacts).map((v, i) => {
                                                return (
                                                    <Paper className={classes.listitem}>
                                                        <ListItem key={"ctct" + i.toString()} onClick={(e) => { displayDetails(v.id); setUpdateState(false); }} button>
                                                            <Typography variant='body1'>{v.entitytype == 'company' ?
                                                                <Box component='span' fontWeight="bold"><span dangerouslySetInnerHTML={{ __html: ExtractLines(v.entityname).join("<br />") }} /></Box>
                                                                : <span dangerouslySetInnerHTML={{ __html: ExtractLines(v.entityname).join("<br />") }} />}
                                                            </Typography>
                                                            {v.entitytype == 'company' && v.contactName != null && v.contactName != "" ?
                                                                <Typography variant='body2' >&nbsp;- <span dangerouslySetInnerHTML={{ __html: v.contactName }} /></Typography>
                                                                : null}
                                                        </ListItem>
                                                    </Paper>
                                                );
                                            })}
                                    </List></React.Fragment>}
                                </Grid>}
                    </Grid>
       
                    </Grid>
                <Grid item xs={12}>
                    <Paper variant="outlined" className={classes.supplemental}>
                        <Typography align="left" className={classes.supplementaltext}>"Dr." Use only for medical doctors unless otherwise noted in the address cell to honor a personal request that they be addressed that way.</Typography>
                        <Typography align="left" className={classes.supplementaltext}>Example: Dr. David Estrada Herrero, and Dr. Frances Luttikhuizen, who are both Ph.D.'s. prefer to be addressed as Dr.</Typography><br />
                        <Typography align="left" className={classes.supplementaltext}>"Professor" is referenced only to honor a personal request that they be addressed that way when speaking to him/her or in written correspondence.</Typography>
                        <Typography align="left" className={classes.supplementaltext}>Example: Professor Martha Bayles.</Typography><br />
                        <Typography align="left" className={classes.supplementaltext}>"Ph.D." in parenthesis (Ph.D.) is referenced only to acknowledge that the addressee is a "Dr." if speaking to him/her directly. The title is not to be used for addressing Christmas cards or correspondence.</Typography>
                        <Typography align="left" className={classes.supplementaltext}>Example: Inger Sigrun Brodey (Ph.D.)</Typography>
                    </Paper>
                </Grid>
            </Grid>
            <Grid item container xs={2} alignItems="stretch" >
                <Grid item container direction="row" alignItems="stretch" xs={12} spacing={1}>
                    <Grid item xs={8}>
                        <Button fullWidth color="secondary" onClick={() => { navList(); }} variant='contained' startIcon={<FontAwesomeIcon size='xs' icon={faPrint} />}><Box fontSize='.7em' component='span'>Print Records</Box></Button>
                    </Grid>
                    {(auth != null && auth.checkGroups("directory")) ?
                        <React.Fragment>
                        <Grid item xs={8}>
                            <Button fullWidth color="primary" onClick={() => { editContact(null); }} variant='contained' startIcon={<FontAwesomeIcon size='xs' icon={faPlus} />}><Box fontSize='.7em' component='span'>Add Contact</Box></Button>
                        </Grid>
                        <Grid item xs={8}>
                            <Button fullWidth color="primary" onClick={() => { navTags(); }} variant='contained' startIcon={<FontAwesomeIcon size='xs' icon={faPlus} />}><Box fontSize='.7em' component='span'>Edit Tags</Box></Button>
                        </Grid>
                        </React.Fragment>
                        : null}
                </Grid>
            </Grid>
        </Grid>
        );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formFields: {
            textAlign: 'left',
            padding: '3px'
        },
        inlineComponent: {
            marginRight: '10px'
        },
        supplemental: {
            backgroundColor: '#e3e3e3'
        },
        supplementaltext: {
            fontSize: '.6em'
        },
        advsearchblock: {
            paddingLeft: '5px',
            paddingBottom: '1px',
            verticalAlign: 'middle',
            marginLeft: '0px'
        },
        advsearchlabel: {
            lineHeight: 1,
            fontSize: '.75em'
        },
        listitem: {
            margin: '5px',
            '&:hover': {
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.primary.contrastText
            }
        },
        letterbutton: {
            minWidth: '0px',
            color: theme.palette.primary.main
        },
        selectedletterbutton: {
            minWidth: '0px'
        },
        popuphost: {
            position: 'relative'
        },
        popupbox: {
            position: 'absolute',
            top: '50px',
            borderWidth: '2px',
            borderColor: 'black',
            borderStyle: 'solid',
            borderRadius: '5px',
            backgroundColor: theme.palette.secondary.main,
            zIndex: 5
        },
        popupcomponent: {
            color: theme.palette.secondary.contrastText,
            '&.Mui-checked': {
                color: theme.palette.secondary.contrastText
            },
            padding: '2px'
        },
        popupButtons: {
            width: '99%',
            justifyContent: 'flex-end'
        },
        popupButton: {
        }
    }));