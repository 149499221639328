import React, { useEffect, useContext, useState, useReducer, Reducer } from 'react';
//import { RestApiRepository } from '../../application/restapirepository';
//import { ApiContext } from '../../contexts/apicontext';
import { IContactDetails, GetDefaultContactDetails } from '../../application/models';

import { EmailEditor } from './contactemails';
import { PhoneEditor } from './contactphonenumbers';
import { UrlEditor } from './contacturls';
import { RelationshipEditor } from './contactrelationships';
import { AddressEditor } from './contactaddresses';
import { UploadPictureDialog } from './pictureupload';

import { Grid, CircularProgress, Typography, Box, TextField, Select, FormControl, MenuItem, OutlinedInput, Button, ButtonGroup, makeStyles, createStyles, Theme  } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faTrash } from '@fortawesome/free-solid-svg-icons';
import { EditEntityTags } from '../tags/entityedit';
import { useFieldsteadApi } from '../../hooks/useFieldsteadApi';

export interface IContactEditProps {
    contactid: string | null | undefined;
    completeEdit: (id: string | null | undefined, success: boolean) => void;
    isCopy: boolean;
}

interface IContactEditState extends IContactDetails {
    flagstring: string;
    isLoading: boolean;
}

interface IContactEditorAction {
    action: "field" | "collection" | "complete" | "loaded";
    payload: { field: string, value: any };
}

const editReducer: Reducer<IContactEditState, IContactEditorAction> = (s, action) => {
    let a = action.payload;
    if (action.action == "field") {
        switch (a.field) {
            case "company":
                return { ...s, companyName: a.value };
            case "displaytype":
                return { ...s, displaytype: a.value };
            case "jobtitle":
                return { ...s, jobtitle: a.value };
            case "department":
                return { ...s, department: a.value };
            case "lastname":
                return { ...s, lastname: a.value };
            case "firstname":
                return { ...s, firstname: a.value };
            case "children":
                return { ...s, children: a.value };
            case "salutation":
                return { ...s, salutation: a.value };
            case "flags":
                return { ...s, flags: a.value.split("\n"), flagstring: a.value };
            case "printednotes":
                return { ...s, publicnotes: a.value };
            case "viewnotes":
                return { ...s, internalnotes: a.value };
            case "image":
                return { ...s, image: a.value, hasimage: a.value != null && a.value.length > 0 };
            default:
                return s;
        }
    }
    else if (action.action == "collection") {
        switch (a.field) {
            case "addresses":
                return { ...s, addresses: a.value };
            case "emails":
                return { ...s, emails: a.value };
            case "relationships":
                return { ...s, relationships: a.value };
            case "urls":
                return { ...s, urls: a.value };
            case "phonenumbers":
                return { ...s, phonenumbers: a.value };
            default:
                return s;
        }
    }
    else if (action.action == "complete") {
        
    }
    else if (action.action == "loaded") {
        let contact: IContactDetails = action.payload.value;
        return { ...contact, flagstring: contact.flags.join("\n"), isLoading: false };
    }
    return s;
}

interface UploadPicState {
    open: boolean;
}

export const ContactEdit: React.FC<IContactEditProps> = (props, context) => {
    const [state, dispatch] = useReducer(editReducer, props.contactid == undefined || props.contactid == null ? { ...(GetDefaultContactDetails()), flagstring: "", isLoading: false } : { ...(GetDefaultContactDetails()), flagstring: "", isLoading: true });
    const { FetchContact, SaveContact } = useFieldsteadApi();
    //const api: RestApiRepository = useContext(ApiContext);
    const [imgstate, setImgstate] = useState<UploadPicState>({ open: false });

    const classes = useStyles();

    useEffect(() => {
        if (state.isLoading == true && props.contactid != null) {
            FetchContact(props.contactid).then((data) => {
                if (props.isCopy) {
                    data.id = "";
                }
                dispatch({ action: "loaded", payload: { field: "contact", value: data } });
                setImgstate({ ...imgstate, open: false });
            });
        }
    }, [state.isLoading]);

    const dispatchFieldUpdate: (element: { field: string, value: string }) => void = (element) => {
        dispatch({ action: "field", payload: element });
    }

    const saveContact: () => void = () => {
        SaveContact(state).then((s: string) => {
            props.completeEdit(s, true);
        });
    }

    const uploadPic: () => void = () => {
        setImgstate({ ...imgstate, open: true });
    }

    const deletePic: () => void = () => {
        if (window.confirm("Are you sure you want to delete this picture?")) {
            dispatch({ action: "field", payload: { field: "image", value: null } });
        }
    }

    const setFile: (file: string) => void = (file) => {
        dispatch({ action: "field", payload: { field: "image", value: file } });
        setImgstate({ ...imgstate, open: false });
    }

    const cancelUpload: () => void = () => {
        setImgstate({...imgstate, open: false });
    }

    const cancelEdit: () => void = () => {
        props.completeEdit(props.contactid, false);
    }

    if (state.isLoading) return (
        <Grid container alignItems='center'>
            <Grid item xs={12}>
                <CircularProgress />
            </Grid>
        </Grid>
    );
    else {
        return (
            <Grid container className={ classes.formContainer } spacing={1} alignItems='flex-start'>
                <UploadPictureDialog open={imgstate.open} cancel={cancelUpload} contact={state} save={setFile} />
                <Grid item xs={12} md={2}>
                    <Typography align="left"><Box fontWeight="fontWeightBold" fontSize='.9em' component="span">Company Name</Box></Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                    <TextField fullWidth variant="outlined" multiline placeholder="Company Name" size="small" value={state.companyName} onChange={(e) => { dispatchFieldUpdate({ field: "company", value: e.target.value }); }}></TextField>
                </Grid>
                <Grid item xs={12} md={2}>
                    <Typography align="left"><Box fontWeight="fontWeightBold" component="span">Display As</Box></Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                    <Select fullWidth value={state.displaytype} variant="outlined" onChange={(e: React.ChangeEvent<{ name?: string | undefined, value: any }>, child: React.ReactNode) => { dispatchFieldUpdate({ field: "displaytype", value: e.target.value }); }}
                        input={<OutlinedInput name="customer" id="clientselect" />}>
                        <MenuItem value="" disabled>Select...</MenuItem>
                        <MenuItem value="company">Company</MenuItem>
                        <MenuItem value="person">Person</MenuItem>
                    </Select>
                </Grid>
                <Grid item container xs={12}>
                    <Grid item xs={12} md={2}><Typography><Box fontWeight="fontWeightBold" component="span">Picture:</Box></Typography></Grid>
                    <Grid item container xs={12} md={10}>
                        {state.hasimage ? <Grid item xs={12}><img src={"data:image/jpg;base64," + state.image} /></Grid> : null}
                        <Grid item xs={12}>
                            <ButtonGroup variant='contained' size="small">
                                <Button color='primary' onClick={uploadPic}><FontAwesomeIcon icon={faUpload} /></Button>
                                <Button color='secondary' onClick={deletePic}><FontAwesomeIcon icon={faTrash} /></Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={2}>
                    <Typography align="left"><Box fontWeight="fontWeightBold" component="span">Job Title</Box></Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                    <TextField fullWidth variant="outlined" size="small" multiline rows={1} placeholder="Job Title" value={state.jobtitle} onChange={(e) => { dispatchFieldUpdate({ field: "jobtitle", value: e.target.value }); }}></TextField>
                </Grid>
                <Grid item xs={12} md={2}>
                    <Typography align="left"><Box fontWeight="fontWeightBold" component="span">Department</Box></Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                    <TextField fullWidth variant="outlined" size="small" multiline rows={1} placeholder="Department" value={state.department} onChange={(e) => { dispatchFieldUpdate({ field: "department", value: e.target.value }); }}></TextField>
                </Grid>
                <Grid item xs={12} md={2}>
                    <Typography align="left"><Box fontWeight="fontWeightBold" component="span">Last Name</Box></Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                    <TextField fullWidth variant="outlined" size="small" placeholder="Last Name" value={state.lastname} onChange={(e) => { dispatchFieldUpdate({ field: "lastname", value: e.target.value }); }}></TextField>
                </Grid>
                <Grid item xs={12} md={2}>
                    <Typography align="left"><Box fontWeight="fontWeightBold" component="span">First Name</Box></Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                    <TextField fullWidth variant="outlined" size="small" placeholder="First Name" value={state.firstname} onChange={(e) => { dispatchFieldUpdate({ field: "firstname", value: e.target.value }); }}></TextField>
                </Grid>
                <Grid item xs={12} md={2}>
                    <Typography align="left"><Box fontWeight="fontWeightBold" component="span">Children</Box></Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                    <TextField fullWidth variant="outlined" size="small" multiline rows={1} placeholder="Children" value={state.children} onChange={(e) => { dispatchFieldUpdate({ field: "children", value: e.target.value }); }}></TextField>
                </Grid>

                <Grid item xs={12} md={2}>
                    <Typography align="left"><Box fontWeight="fontWeightBold" component="span">Emails</Box></Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                    <EmailEditor updateEmails={(a) => { dispatch({ action: "collection", payload: { field: "emails", value: a } }); }} currentemails={state.emails} />
                </Grid>

                <Grid item xs={12} md={2}>
                    <Typography align="left"><Box fontWeight="fontWeightBold" fontSize=".9em" component="span">Phone Numbers</Box></Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                    <PhoneEditor updatePhoneNumbers={(a) => { dispatch({ action: "collection", payload: { field: "phonenumbers", value: a } }); }} currentphonenumbers={state.phonenumbers} />
                </Grid>

                <Grid item xs={12} md={2}>
                    <Typography align="left"><Box fontWeight="fontWeightBold" component="span">Relationships</Box></Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                    <RelationshipEditor updateRelationships={(a) => { dispatch({ action: "collection", payload: { field: "relationships", value: a } }); }} currentrelationships={state.relationships} />
                </Grid>

                <Grid item xs={12} md={2}>
                    <Typography align="left"><Box fontWeight="fontWeightBold" component="span">URLs</Box></Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                    <UrlEditor updateUrls={(a) => { dispatch({ action: "collection", payload: { field: "urls", value: a } }); }} currenturls={state.urls} />
                </Grid>

                <Grid item xs={12} md={2}>
                    <Typography align="left"><Box fontWeight="fontWeightBold" component="span">Addresses</Box></Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                    <AddressEditor updateAddresses={(a) => { dispatch({ action: "collection", payload: { field: "addresses", value: a } }); }} currentaddresses={state.addresses} />
                </Grid>

                <Grid item xs={12} md={2}>
                    <Typography align="left"><Box fontWeight="fontWeightBold" component="span">Salutation</Box></Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                    <TextField fullWidth variant="outlined" multiline rows={3} size="small" placeholder="Salutation" value={state.salutation} onChange={(e) => { dispatchFieldUpdate({ field: "salutation", value: e.target.value }); }}></TextField>
                </Grid>
                <Grid item xs={12} md={2}>
                    <Typography align="left"><Box fontWeight="fontWeightBold" component="span">Flags</Box></Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                    <TextField fullWidth size="small" multiline rows="4" variant="outlined" placeholder="Flags (separated by newline)" value={state.flagstring} onChange={(e) => { dispatchFieldUpdate({ field: "flags", value: e.target.value }); }}></TextField>
                </Grid>
                <Grid item xs={12} md={2}>
                    <Typography align="left"><Box fontWeight="fontWeightBold" component="span">Printed Notes</Box></Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                    <TextField fullWidth size="small" multiline rows="4" variant="outlined" placeholder="Printed Notes" value={state.publicnotes} onChange={(e) => { dispatchFieldUpdate({ field: "printednotes", value: e.target.value }); }}></TextField>
                </Grid>
                <Grid item xs={12} md={2}>
                    <Typography align="left"><Box fontWeight="fontWeightBold" component="span">View Only Notes</Box></Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                    <TextField fullWidth size="small" multiline rows="4" variant="outlined" placeholder="View Only Notes" value={state.internalnotes} onChange={(e) => { dispatchFieldUpdate({ field: "viewnotes", value: e.target.value }); }}></TextField>
                </Grid>
                {props.contactid != null ?
                    <React.Fragment>
                        <Grid item xs={12} md={2}>
                            <Typography align="left"><Box fontWeight="fontWeightBold" component="span">Tags</Box></Typography>
                        </Grid>
                        <Grid item xs={12} md={10}>
                            <EditEntityTags entityid={ props.contactid } entitytype="Contact" />
                        </Grid>
                    </React.Fragment>
                    : null}
                <Grid item xs={1} md={8}>
                </Grid>
                <Grid item className={ classes.buttonBar } container xs={11} md={4}>
                    <Grid item xs={6}>
                        <Button variant='contained' color='primary' onClick={(e) => { saveContact(); }}>Submit</Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button variant='contained' color='secondary' onClick={(e) => { cancelEdit(); }}>Reset</Button>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formContainer: {
            paddingTop: '30px',
            paddingBottom: '30px'
        },
        buttonBar: {
            marginTop: '30px'
        }
    }));